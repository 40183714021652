import React from 'react'
import PropTypes from 'prop-types'
import { Card, CircularProgress } from '@mui/material'

const CardsBlock = ({data, handleSelect, isLoading, filterBy}) => {

  const cutMinus = (value) => {
    return value > 0 ? value : Math.abs(value)  
  }

  return (
    <div className="card-container">
      {data?.map((card, i) => (
        <Card
          sx={{ minWidth: 250 }}
          className={`card card-dashboard${card.query === filterBy ? ' active' : ''}`}
          onClick={() => handleSelect(card.query)}
          key={card.title + i}
        >
          {isLoading
            ? <CircularProgress />
            : (
              <>
              <div className="header">
                {card.title}
              </div>
              <div className="value">
                {cutMinus(card.value)}
              </div>
              <div className={`percentage${card.dif > 0 ? ' up' : ' down'}`}>
                <span>{card.dif > 0 ? 'Up' : 'Down'}</span>{cutMinus(card.dif)}% 
              </div>
              <div className="relation">vs Yesterday</div>
              </>
            )
          }
        </Card>
      ))}
  </div> 
  )
}

CardsBlock.propTypes = {
  data: PropTypes.array,
  handleSelect: PropTypes.func,
  isfilterBy: PropTypes.bool,
  filterBy: PropTypes.string,
}

export default CardsBlock