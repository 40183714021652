import React, {useEffect} from 'react'
import {DataGrid, GridCellParams, GridToolbar} from '@mui/x-data-grid';
import { useState } from 'react';
import { useQuery } from "react-query";
import {CircularProgress} from "@mui/material";
import axios from "axios";

const fetchCompanyAnalytics = async (page) => {
  const response = await axios.get(`/api/company-analytics?page=${page}`)
  return response.data
}

const Tag = (accountStatus) => {
  switch (accountStatus) {
    case 'trial':
      return(
        <span>Trial</span>
      )
    case 'past_due':
      return (
        <span>Past Due</span>
      )
    case 'cancelled':
      return (
        <span>Cancelled</span>
      )
    case 'inactive':
      return (
        <span>Inactive</span>
      )
    default:
      return(
        <span>Not Available</span>
      )
  }
}

const columns = [
  { field: 'name', headerName: 'Account Name', width: 150 },
  { field: 'active_users_24', headerName: 'Active Users 24 H', width: 150 },
  { field: 'monthly_users', headerName: 'Monthly Active', width: 150 },
  { field: 'weekley_active_users', headerName: 'Weekly Active Users', width: 150 },
  { field: 'number_of_admin', headerName: 'Number of Admin', width: 150 },
  { field: 'admin_usage_last_24_hours', headerName: 'Admin Usage H', width: 150},
  { field: 'subscription_status', headerName: 'Account Status', width: 150},
  { field: 'trial', headerName: 'Trial Status', width: 150 },
  { field: 'trial_expiration', headerName: 'Trial Exp', width: 150}
];

const CompanyAnalytics = () => {
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState()
  const [page, setPage] = useState(1)
  const {data: companyData, isLoading, isFetching, refetch} = useQuery('companyAnalytics', async () => await fetchCompanyAnalytics(page), {refetchOnWindowFocus: false})


  useEffect(() => {
    if (!isLoading && !isFetching) {
      setRows(companyData.data)
      setRowCount(companyData.total)
    }
  })

  useEffect(() => {
    refetch()
  }, [page])

  return (
    <div style={{ height: 500, width: '100%' }} className="company-tables">
      <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'monthly_users', sort: 'desc' }],
            },
          }}
          rows={rows}
          rowCount={rowCount}
          loading={isFetching}
          rowsPerPageOptions={[]}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          paginationMode="server"
          columns={columns}
      />
    </div>
  );
}

export default CompanyAnalytics
