var flashMessageTimer;

$(function (){
    hideFlashMessage()
})

function showFlashMessage(message) {
    let $flashContainer = $('.flash-container'),
        $flash = $('.flash', $flashContainer);

    $flash.text(message)
    $flashContainer.slideDown();
    hideFlashMessage()
}

function hideFlashMessage(){
    clearTimeout(flashMessageTimer);
    flashMessageTimer = setTimeout(function (){
        $('.flash-container').slideUp();
    }, 5000)
}
