import axios from "axios";

export const fetchAIStatus = async () => {
  const response = await axios.get(`api/ai-status-message/edit-prompt`)
  return response.data.result
}

export const updateAIStatusMessage = async (data) => {
  const response = await axios.put(`api/ai-status-message/update-prompt`, data)
  return response.data
}

export const previewAIStatusMessage = async (data) => {
  const response = await axios.put(`api/ai-status-message/preview`, data)
  return response.data 
}