import React, {useEffect, useState}  from 'react'
import { useQuery, useMutation } from 'react-query';
import { fetchCompanyAIPrompts, updateCompanyAIPrompt } from '../../utils/backend-api/company_ai_prompt'; 
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Textarea from '@mui/joy/Textarea';
import toast from 'react-hot-toast';
import Button from '@mui/joy/Button';




const Layout = ({companyId, listOfAIPrompts}) => {
  const {isLoading, data} = useQuery(`CompanyAIPrompts${companyId}`,async () => await fetchCompanyAIPrompts(companyId))
  const [currentPrompt, setCurrentPrompt] = useState()
  const handleOptionChange = (event) => {
    const aiPrompt = data.companyAiPrompts.find((aiPropmpt) => aiPropmpt.prompt_type === event.target.value)
    setCurrentPrompt(aiPrompt);
  };

  const updatePrompts = async () => {
    const response = await updateCompanyAIPrompt({
      prompt_id: currentPrompt.id,
      prompt: currentPrompt.prompt,
    })
    if (response.success) {
      toast.success('Updated Succesfully')
    } else {
      toast.error('Failed to Update')
    }
  }

  if (isLoading) return null;
  console.log(currentPrompt)
  console.log(data?.companyAiPrompts)
  return (
    <div className='companyAIPrompt'>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Prompts</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentPrompt?.prompt_type}
          label="Prompt Type"
          onChange={handleOptionChange}
        >

        {data?.companyAiPrompts.map((aiPropmpt) => (
          <MenuItem key={aiPropmpt.id} value={aiPropmpt.prompt_type}>{aiPropmpt.prompt_type}</MenuItem>
        ))}
        </Select>
        {currentPrompt?.prompt && (
          <div className='prompt-container'>
            <Textarea
              placeholder="New Prompt"
              required
              readOnly={false}
              minRows={6}
              sx={{ mb: 1 }}
              value={currentPrompt?.prompt}
              onChange={(e) => setCurrentPrompt(prevState => ({
                ...prevState,
                prompt: e.target.value
              }))}
            />
            <Button onClick={updatePrompts}>Update</Button>
          </div>
        )}

      </FormControl>
     
    </div>
  )
}

export default Layout;