import React from "react";
import {QueryClient, QueryClientProvider} from 'react-query'
import Layout from "./Layout";


const queryClient = new QueryClient()
const Dashboard = () => {
  return (
    <QueryClientProvider client={queryClient}>
     <Layout/>
    </QueryClientProvider>
  )
}

export default Dashboard