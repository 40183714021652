import React from "react";
import {QueryClient, QueryClientProvider} from 'react-query'
import Layout from "./Layout";
import {Toaster } from 'react-hot-toast';


const queryClient = new QueryClient()
const CompanyAIPrompt = ({companyId, listOfAIPrompts}) => {
  return (
    <QueryClientProvider client={queryClient}>
     <Layout companyId={companyId} listOfAIPrompts={listOfAIPrompts}/>
     <Toaster/>
    </QueryClientProvider>
  )
}

export default CompanyAIPrompt