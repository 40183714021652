import React from 'react'
import Card from '@mui/material/Card'
import { CircularProgress } from '@mui/material';

const Cards = ({ 
  newSignUpAccount,
  accountsExpiring24Hours,
  accountsExpiring3Days,
  accountsExpiring7Days,
  setCurrentOverView,
  isLoading,
}) => {
 return (
  <div className="card-container">
    <Card sx={{ minWidth: 250 }} className='card' onClick={() => setCurrentOverView(newSignUpAccount) }>
      {isLoading
        ? <CircularProgress />
        : (
          <>
            <div className="header">
              New SignUps
            </div>
            <div className="value">
              {newSignUpAccount?.length}
            </div>
          </>
        )
      }
    </Card>
    <Card sx={{ minWidth: 250 }} className="card" onClick={() => setCurrentOverView(accountsExpiring24Hours) }>
      {isLoading
        ? <CircularProgress />
        : (
          <>
            <div className="header">
              Trial Expire In 24 Hours
            </div>
            <div className="value">
              {accountsExpiring24Hours?.length}
            </div>   
          </>
        )
      } 
    </Card>
    <Card sx={{ minWidth: 250 }} className="card" onClick={() => setCurrentOverView(accountsExpiring3Days) }>
      {isLoading
        ? <CircularProgress />
        : (
          <>
            <div className="header">
              Trial Expire In 3 Days
            </div>
            <div className="value">
              {accountsExpiring3Days?.length}
            </div>
          </>
        )
      }
    </Card>
    <Card sx={{ minWidth: 250 }} className="card" onClick={() => setCurrentOverView(accountsExpiring7Days) }>
      {isLoading
        ? <CircularProgress />
        : (
          <>
            <div className="header">
              Trial Expire In 7 Days
            </div>
            <div className="value">
              {accountsExpiring7Days?.length}
            </div>
          </>
        )
      }
    </Card>
  </div> 
 )
}

export default Cards;