import axios from "axios";

export const fetchCompanyAIPrompts = async (companyId) => {
  const response = await axios.get(`/api/company-ai-prompts/${companyId}`,)
  return response.data
}

export const updateCompanyAIPrompt = async (data) => {
  const response = await axios.put('/api/company-ai-prompts/update-prompts', data)
  return response.data
}