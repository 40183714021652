import React from 'react'
import PropTypes from 'prop-types'
import { Box, Modal, Typography } from '@mui/material'
import '../../../stylesheets/react/dashboard/modal.scss'

const DashboardModal = ({open, handleClose, title}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="dashboard-modal">
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
      </Box>
    </Modal>
  )
}

DashboardModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
}

export default DashboardModal
