import React, {useState}  from 'react'
import Cards from './Cards'
import Overview from "./Overview";
import { useQuery } from 'react-query';
import { fetchCompanysData, fetchOverviewData } from '../../utils/backend-api/dashboard';
import CompanyAnalytics from "./companyAnalytics";
import CardsBlock from '../CardsBlock';
import { Box, CircularProgress } from '@mui/material';
import CompanysDetailsTable from './companysDetailsTable';
import MostRecentCompanyAnalytics from './MostRecentCompanyAnalytics';

const Layout = () => {
  const [cardsData, setCardsData] = useState([])
  const [currentOverview, setCurrentOverView] = useState();
  const [newSignUpAccount, setNewSignUpAccount] = useState();
  const [accountsExpiring3Days, setAccountsExpiring3Days] = useState();
  const [accountsExpiring7Days, setAccountsExpiring7Days] = useState();
  const [accountsExpiring24Hours, setAccountsExpiring24Hours] = useState();
  const [filterBy, setFilterBy] = useState('admin_portal_logo')
  const {isLoading } = useQuery('DashboardData',async () => await fetchOverviewData() , {
    onSuccess: ({
      accounts_expiring_3_days,
      accounts_expiring_7_days,
      accounts_expiring_24_hours,
      new_sign_up_account
    }) => {
      setCurrentOverView(new_sign_up_account)
      setNewSignUpAccount(new_sign_up_account)
      setAccountsExpiring24Hours(accounts_expiring_24_hours)
      setAccountsExpiring7Days(accounts_expiring_7_days)
      setAccountsExpiring3Days(accounts_expiring_3_days)
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  })

  const companysNames = {
    admin_portal_logo: 'Admin Portal Logo',
    extension_logo: 'Extension Logo',
    helpdesk_email: 'Helpdesk Email',
    extension_message_tags: 'Extension Message Tags',
    admin_invites: 'Admin Invites',
    application_configuration: 'Application Configuration',
    invite_extension_users: 'Invited Extension Users',
  }

  const transformArr = company => {
    return {
      title: companysNames[company.query] || 'N/A',
      query: company.query,
      value: company.count,
      dif: company.percentage,
    }
  }

  const {data, isLoading: companysLoading} = useQuery('CompanysData', async () => await fetchCompanysData() , {
    select: data => data.map(company => transformArr(company)),
    onSuccess: data => setCardsData(data),
    refetchOnWindowFocus: false
  })

  return (
    <>
      {/* <Cards
        newSignUpAccount={newSignUpAccount}
        accountsExpiring24Hours={accountsExpiring24Hours}
        accountsExpiring3Days={accountsExpiring3Days}
        accountsExpiring7Days={accountsExpiring7Days}
        setCurrentOverView={setCurrentOverView}
        isLoading={isLoading}
      /> */}
      {/* <Overview
        currentOverView={currentOverview}
      /> */}
      <MostRecentCompanyAnalytics/>
      {/* <CompanyAnalytics /> */}
      {/* {companysLoading ? <CircularProgress/> : (
        <>
          <CardsBlock
              data={cardsData}
              filterBy={filterBy}
              handleSelect={setFilterBy}
              isLoading={companysLoading}
          />
          <Box className="mb-4">
            <CompanysDetailsTable filterBy={filterBy} />
          </Box>
        </>
      )} */}
    </>
  )
}

export default Layout;
