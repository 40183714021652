import React, {useEffect, useState} from 'react'
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import {CircularProgress} from "@material-ui/core";
import axios from 'axios';

const fetchRecentCompanyAnalytics = async () => {
  const response = await axios.get(`/api/recent-analytics`)
  debugger
  return response.data
}

const columns = [
  {
    field: 'name',
    headerName: 'Account Name',
    width: 150,
    renderCell: (params) => <a target="_blank" href={`https://${params.row.name}.hubbleiq.net`}>{params.row.name}</a>
  },
  { field: 'number_of_activities', headerName: 'Number of Activities', width: 150 },
  { field: 'created_at', headerName: 'Created At', width: 250 },
  { field: 'total_activities', headerName: 'Total Activities', width: 150},
  { field: 'contact_email', headerName: 'Contact Email', width: 250 },
  { field: 'first_name', headerName: 'First Name', width: 250},
  { field: 'last_name', headerName: 'Last Name', width: 250}
];

const MostRecentCompanyAnalytics = () => {
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState()
  const {data: companyData, isLoading, isFetching} = useQuery('RecentcompanyAnalytics', async () => await fetchRecentCompanyAnalytics(), {refetchOnWindowFocus: false})

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setRows(companyData.data)
      setRowCount(companyData.total)
    }
  })

  return (
    <div style={{ height: 900, width: '100%' }} className="company-tables">
      <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'created_at', sort: 'desc' }],
            },
          }}
          rows={rows}
          rowCount={rowCount}
          loading={isFetching}
          rowsPerPageOptions={[]}
          pageSize={100}
          columns={columns}
      />
    </div>
  );
}

export default MostRecentCompanyAnalytics;
