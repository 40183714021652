import axios from "axios";

export const fetchOverviewData = async () => {
  const response = await axios.get('/api/analytics')
  return response.data.data
}

export const fetchCompanysData = async (filterBy = 'all') => {
  const response = await axios.get(`/api/companies-analytics?filter_by=${filterBy}`)
  return response.data
}