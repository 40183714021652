import React, {useEffect, useState} from 'react'
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import { fetchCompanysData } from '../../../utils/backend-api/dashboard';
import { useQuery } from 'react-query';
import {CircularProgress} from "@material-ui/core";

const CompanysDetailsTable = ({filterBy}) => {
  const [rows, setRows] = useState([])
  const {
    data,
    isLoading: loading,
    refetch,
    isFetching
  } = useQuery('CompanysTableData', async () => await fetchCompanysData(filterBy) , {
    refetchOnWindowFocus: false,
    disabled: true,
  })

  useEffect(() => {
    if (filterBy) {
      refetch()
    }
  }, [filterBy])

  useEffect(() => {
    if (data) {
      setRows(data)
    }
  }, [data])

  const columns = [
    { field: 'company_name', headerName: 'Company Name', flex: 1},
    { field: 'count', headerName: 'Count', flex: 1 },
  ];

  return (
    <div style={{width: '100%', height: 500 }} className="company-tables">
      {(loading || isFetching) ? <CircularProgress/> : (
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'count', sort: 'desc' }],
            },
          }}
          rows={rows || []}
          columns={columns}
          hideFooterPagination={true}
          loading={loading}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.company_name}
        />
      )}
    </div>
  );
}

export default CompanysDetailsTable
