import React, {useState} from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Textarea from '@mui/joy/Textarea';
import toast from 'react-hot-toast';
import Button from '@mui/joy/Button';
import Switch from '@mui/material/Switch';
import {
  updateAIStatusMessage,
  previewAIStatusMessage,
  fetchAIStatus
} from '../../utils/backend-api/status_ai_prompt'
import { useQuery } from 'react-query';



const Layout = () => {
  const {isLoading, data} = useQuery(`FetchAIStatus`, async () => await fetchAIStatus())
  const [currentStatusmessage, setcurrentStatusMessage] = useState()
  const [isFetchingFromAI, setIsFetchFromAI] = useState(false)

  const handleOptionChange = (event) => {
    const aiPrompt = data.find((aiPropmpt) => aiPropmpt.status_type === event.target.value)
    setcurrentStatusMessage(aiPrompt);
    console.log(aiPrompt)
  };

  const updateStatusPrompts = async () => {
    const response = await updateAIStatusMessage({
      id: currentStatusmessage.id,
      ai_header: currentStatusmessage.ai_header,
      ai_subheader: currentStatusmessage.ai_subheader,
      ai_active: currentStatusmessage.ai_active,
      ai_prompt: currentStatusmessage.ai_prompt
    })
    if (response.success) {
      toast.success('Updated Succesfully')
    } else {
      toast.error('Failed to Update')
    }
  }

  const previewPromptResult = async () => {
    setIsFetchFromAI(true)
    const response = await previewAIStatusMessage({
      prompt: currentStatusmessage.ai_prompt
    })
    if (response.success) {
      setcurrentStatusMessage((prevState) => ({
        ...prevState,
        ai_header: response.ai_header,
        ai_subheader: response.ai_subheader
      }))
    } else {
      toast.error(response.message)
    }
    setIsFetchFromAI(false)
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className='StatusAIPrompt'>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">AI Prompts</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentStatusmessage?.status_type}
          label="Prompt Type"
          onChange={handleOptionChange}
        >

        {data.map((status) => (
          <MenuItem key={status.id} value={status.status_type}>{status.status_type}</MenuItem>
        ))}
        </Select>

        {currentStatusmessage?.status_type && (
          <div className='prompt-container'>
            <Textarea
              placeholder="AI Prompt"
              required
              readOnly={false}
              minRows={6}
              sx={{ mb: 1 }}
              value={currentStatusmessage?.ai_prompt || ''}
              onChange={(e) => setcurrentStatusMessage(prevState => ({
                ...prevState,
                ai_prompt: e.target.value
              }))}
            />
            <Textarea
              disabled
              placeholder="AI Header"
              required
              readOnly={false}
              minRows={6}
              sx={{ mb: 1 }}
              value={currentStatusmessage?.ai_header || ''}
              onChange={(e) => setcurrentStatusMessage(prevState => ({
                ...prevState,
                ai_prompt: e.target.value
              }))}
            />
            <Textarea
              disabled
              placeholder="AI SubHeader"
              required
              readOnly={false}
              minRows={6}
              sx={{ mb: 1 }}
              value={currentStatusmessage?.ai_subheader || ''}
              onChange={(e) => setcurrentStatusMessage(prevState => ({
                ...prevState,
                ai_sub_header: e.target.value
              }))}
            />
            <div className='action-container'>
              <Switch checked={currentStatusmessage?.ai_active || false} onChange={(e) => setcurrentStatusMessage(prevState => ({
                ...prevState,
                ai_active: e.target.checked
              }))}/>
              <Button onClick={previewPromptResult}>{isFetchingFromAI ? 'Fetching ...' : 'Preview'}</Button>
              <Button onClick={updateStatusPrompts}>Update</Button>
            </div>
            
          </div>
        )}

      </FormControl>
   
    </div>
  )
}

export default Layout;