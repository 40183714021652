import React, {useState} from 'react';
import Map from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const viewPort = {
  width: "100vw",
  height: "100vh",
  latitude: 42.430472,
  longitude: -123.334102,
  zoom: 16
}
const mapBoxToken = 'pk.eyJ1IjoiYWJlcmVuamlhbjg5IiwiYSI6ImNsNWZvY3cycDFjejMzY210amRodDJhdmgifQ.NUUAD63uq3IkhK_pvzlBdg'

const MapView = () => {
  const [viewPort, setViewPort] = useState(viewPort)

  console.log(mapBoxToken)
  return (
    <div>
      <Map
        initialViewState={{
          longitude: -122.4,
          latitude: 37.8,
          zoom: 14
        }}
        style={{width: 600, height: 400}}
        mapStyle="mapbox://styles/mapbox/streets-v9"
      />
    </div>
  )
}

export default MapView;

