import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button'

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
];

const Overview = ({currentOverView}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const setToken = (status) => {
    switch (status) {
      case "trial":
        return (
          <span className="subscription-status trial-status">Trial</span>
        )
      case "active":
        return (
          <span className="subscription-status active-status">Active</span>
        )
      default:
        return (
          <span>N/A</span>
        )
    }
  }

  return (
    <div className="overView">
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell>Account</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Subscription Status</TableCell>
              <TableCell align="center">Company Type</TableCell>
              <TableCell align="center">Use Case</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentOverView?.map((row) => (
              <TableRow key={row?.name}>
                <TableCell component="th" scope="row">
                  {row?.name}
                </TableCell>
                <TableCell align="center">{row?.first_name && row?.last_name ? `${row?.first_name} ${row?.last_name}` : 'N/A'}</TableCell>
                <TableCell align="center">{row?.email}</TableCell>
                <TableCell align="center">{setToken(row?.subscription_status)}</TableCell>
                <TableCell align="center">{row?.company_type || 'N/A'}</TableCell>
                <TableCell align="center">{row?.use_case || 'N/A'}</TableCell>
                <TableCell align="center">
                  <Button color="primary" onClick={() => window.location = `/company/${row.id}`}>
                    More Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
    </div>
  )
}

export default Overview;