import React from 'react'
import {QueryClient, QueryClientProvider} from 'react-query'
import {Toaster} from 'react-hot-toast';
import Layout from './Layout';

const queryClient = new QueryClient()
const AIMessage = ({messages}) => {

  return (
    <QueryClientProvider client={queryClient}>
      <Layout messages={messages}/>
      <Toaster/>
    </QueryClientProvider>
  )
}

export default AIMessage;